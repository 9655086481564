@import "./styles/normalize.scss";
@import "./styles/include.scss";
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  text-decoration: none;
  color: #fff;
}

html,
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Oswald", sans-serif;
  background: linear-gradient(45deg, #0c1218, #1a156f);
  overflow-x: hidden;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  .button {
    position: relative;
    padding: 12px 24px;
    border-radius: 6px;
    border: 1px solid white;
    width: fit-content;
    cursor: pointer;
    top: 40px;
    transition: 0.6s ease;

    &:hover {
      background-color: white;
      color: #1a156f;
    }

    .outline {
    }
  }

  @keyframes scaleBtn {
    0% {
      transform: rotate(0) scale(1);
    }
    100% {
      transform: rotate(0) scale(0.85);
    }
  }

  @keyframes scaleBg {
    0% {
      transform: rotate(0) scale(1);
    }
    100% {
      transform: rotate(0) scale(1.09);
    }
  }

  @keyframes bgOut {
    0% {
      transform: rotate(0) scale(1.09);
    }
    100% {
      transform: rotate(0) scale(1);
    }
  }
  @keyframes scaleBgc {
    0% {
      transform: rotate(0) scale(1.1);
    }
    100% {
      transform: rotate(0) scale(1);
    }
  }

  @keyframes bgOutc {
    0% {
      transform: rotate(0) scale(1);
    }
    100% {
      transform: rotate(0) scale(1.1);
    }
  }

  .noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  }
  nav {
    width: 100%;
    position: fixed;
    z-index: 4;
    padding-top: 32px;
    top: 0;
    left: 0;

    &:before {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      right: 0;
      height: 16.2rem;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      pointer-events: none;
      background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(rgba(0, 0, 0, 0.6)),
        color-stop(71%, rgba(0, 0, 0, 0.15)),
        to(transparent)
      );
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.6) 0,
        rgba(0, 0, 0, 0.15) 71%,
        transparent
      );
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#00000000",GradientType=0);
    }

    @keyframes yourAnimation {
      0% {
        transform: rotate(0);
        transform-origin: 0% 0%;
        opacity: 1;
      }
      58% {
        transform: rotate(6deg) translateY(35%);
        transform-origin: 0% 0%;
        opacity: 0.1;
      }
      60% {
        transform: rotate(-6deg) translateY(-35%);
        transform-origin: 0% 0%;
        opacity: 0.1;
      }
      99% {
        transform: rotate(0);
        transform-origin: 0% 0%;
        opacity: 1;
      }
    }

    .container {
      max-width: 1440px;
      margin: 0 auto;
      padding: 0 12px;

      @include media("<=1250px") {
        padding: 0 24px;
      }

      .nav-inner {
        display: flex;
        align-items: baseline;
        justify-content: space-between;

        &__logo {
          width: 100px;
          &-inner {
            width: 100%;
            height: 55px;
            display: flex !important;
            align-items: center;
            justify-content: space-between !important;

            .logo-name {
            }

            svg {
              width: 100%;
              height: 100%;
            }

            span {
              width: 20px;
            }
          }
        }
        &__list {
          min-width: 1050px;

          @include media("<=1380px") {
            display: none;
          }

          &-inner {
            width: 100%;

            .nav__list {
              width: 100%;
              display: flex;
              justify-content: space-between;
              list-style: none;
              text-transform: uppercase;
              font-weight: 400;
              font-size: 19px;
              letter-spacing: 3px;

              &-item {
                cursor: pointer;
                -webkit-touch-callout: none; /* iOS Safari */
                -webkit-user-select: none; /* Safari */
                -khtml-user-select: none; /* Konqueror HTML */
                -moz-user-select: none; /* Old versions of Firefox */
                -ms-user-select: none; /* Internet Explorer/Edge */
                user-select: none; /* Non-prefixed version, currently
                                                supported by Chrome, Edge, Opera and Firefox */
              }

              &-item:hover {
                position: relative;
                animation: yourAnimation 0.5s forwards 0.3s ease;
              }
            }
          }
        }
        &__lang {
          &-inner {
            width: 100px;
            display: flex;
            justify-content: flex-end;
            .nav__lang-list {
              display: flex;
              list-style: none;
              position: relative;
              font-weight: 400;
              font-size: 19px;
              letter-spacing: 3px;

              &-item {
                a {
                  span {
                  }
                }
              }

              &-item:nth-child(1) {
                margin-right: 16px;
              }
              &-item:nth-child(2) {
                &::before {
                  content: "";
                  position: absolute;
                  left: 50%;
                  top: calc(50% - 0.8rem);
                  background-color: #f9f9f2;
                  width: 0.1rem;
                  height: 1.6rem;
                }
              }
            }
          }
        }
      }
    }
  }
  .App {
    position: relative;
    overflow-x: hidden;
    visibility: hidden;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    .container {
      max-width: 1440px;
      margin: 0 auto;
      padding: 0 12px;

      @include media("<=1250px") {
        padding: 0 24px;
      }
    }

    header {
      height: 100vh;
      position: relative;
      overflow: hidden;

      .header-bg {
        &__buildings {
          &.animateBg {
            animation: scaleBg 1s forwards 0s ease;
          }
          &.outBg {
            animation: bgOut 1s forwards 0s ease;
          }
          width: 100%;
          height: 100%;
          background-image: url("../media/pbs-building@2x.a50a3aa3.png");
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          position: absolute;
          z-index: 2;
        }

        &__clouds {
          &.animateBg {
            animation: scaleBgc 1s forwards 0s ease;
          }
          &.outBg {
            animation: bgOutc 1s forwards 0s ease;
          }
          width: 100%;
          height: 100%;
          background-image: url("../media/pbs-clouds@2x.dd51593b.jpg");
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          position: absolute;
          z-index: 1;
          transform: scale(1.1);
        }
      }
      .container {
        .header-inner {
          height: 100vh;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: relative;
          z-index: 3;

          &__content {
            display: flex;
            flex-direction: column;
            align-items: center;
            max-width: 1150px;
            margin: 0 auto;

            &-subtitle {
              overflow: hidden;
              &__inner {
                text-align: center;
                font-size: 24px;
                font-weight: 400;
                letter-spacing: 4.8px;

                span {
                  color: #64a8e0;
                }

                @include media("<=tablet") {
                  max-width: 200px;
                  margin: 0 auto;
                  font-size: 0.8rem;
                  line-height: 1.4;
                }
              }
              margin-bottom: 20px;
            }
            &-title {
              &__inner {
                font-size: 4rem;
                font-weight: 500;
                text-align: center;
                padding: 0;
                margin: 0;

                .content-title__line {
                  overflow: hidden;
                }

                span {
                  color: #de3350;
                }

                @include media("<=tablet") {
                  font-size: 1.2rem;
                  line-height: 28px;
                }
              }
              margin-bottom: 25px;
            }
            &-play {
              &:hover {
                animation: scaleBtn 1s forwards 0s ease;
              }

              &__inner {
                width: 100px;
                cursor: pointer;
              }
            }
          }

          &__action {
            position: absolute;
            bottom: 0;

            &-discover {
              position: relative;
              overflow: hidden;

              .label {
                height: 20px;
                overflow: hidden;
                margin-bottom: 10px;
                &__inner {
                  margin-bottom: 16px;
                  text-transform: uppercase;
                  overflow: hidden;
                }
              }

              &__line {
                content: "";
                position: relative;
                left: 50%;
                top: calc(50% - 0.8rem);
                background-color: #f9f9f2;
                width: 0.1rem;
                height: 2rem;
              }
            }
          }
        }
      }
    }

    section {
      width: 100%;
      position: absolute;

      .container {
        .section-inner {
          height: 100vh;
          padding-top: 120px;
          display: flex;
          position: relative;

          @include media("<=tablet") {
            padding-top: 0px;
          }

          &__content {
            position: absolute;
            top: 450px;

            @include media("<=tablet") {
              top: 210px;
            }

            .content-inner {
              max-width: 58%;
              margin-left: 120px;

              @include media("<=tablet") {
                max-width: 90%;
                margin-left: 16px;
              }

              &__subtitle {
                margin-bottom: 22px;
                @include media("<=tablet") {
                  width: 80%;
                  margin-bottom: 0px;
                }

                h4 {
                  font-size: 16px;
                  line-height: 18.2px;
                  font-weight: 500;
                  text-transform: uppercase;
                  letter-spacing: 0.3rem;

                  span {
                    color: #64a8e0;
                  }

                  @include media("<=tablet") {
                    font-size: 12px;
                  }
                }
              }
              &__title {
                h3 {
                  font-size: 3rem;
                  font-weight: 300;
                  font-family: "Rubik", sans-serif;
                  span {
                    color: #de3350;
                    font-weight: 500;
                  }
                  @include media("<=tablet") {
                    font-size: 1.2rem;
                  }
                }
              }
            }
          }
          &__media {
            .media-inner {
              max-width: 75%;
              margin-left: auto;

              &__subtitle {
                margin-bottom: 22px;

                h4 {
                  font-size: 16px;
                  line-height: 18.2px;
                  font-weight: 500;
                  text-transform: uppercase;
                  letter-spacing: 0.3rem;

                  span {
                    color: #64a8e0;
                  }
                }
              }
              &__title {
                h3 {
                  font-size: 3rem;
                  font-weight: 300;
                  font-family: "Rubik", sans-serif;
                  span {
                    color: #de3350;
                    font-weight: 500;
                  }
                }
              }

              @include media("<=tablet") {
                max-width: 100%;
              }

              img {
                width: 100%;
              }
            }
          }
        }
        .section-business {
          width: 1400px;
          position: absolute;
          right: -140px;
          top: 95%;

          @include media("<=tablet") {
            width: 290px;
            top: 44%;
          }
        }
        .section-tech {
          width: 2082px;
          position: absolute;
          right: -200px;
          top: 146%;

          @include media("<=tablet") {
            width: 452px;
            top: 57%;
            left: -180px;
          }
        }
        .section-data {
          width: 2082px;
          position: absolute;
          z-index: 1;
          right: -90%;
          top: 188%;

          @include media("<=tablet") {
            width: 452px;
            top: 68%;
          }
        }
        .section-process {
          width: 1674px;
          position: absolute;
          z-index: 1;
          right: 10%;
          top: 229%;

          @include media("<=tablet") {
            width: 340px;
            top: 79%;
            left: -24%;
          }
        }
      }
    }

    #info {
      top: 345%;

      @include media("<=tablet") {
        top: 185%;
      }

      .container {
        .section-inner {
          &__content {
            .content-inner {
              margin-left: auto;
            }
          }
          &__media {
            .media-inner {
              max-width: 75%;
              margin-left: 0;

              @include media("<=tablet") {
                max-width: 100%;
              }

              img {
                width: 100%;
              }
            }
          }
        }
      }
    }

    #services {
      top: 480%;

      .container {
        .section-inner {
          width: 100%;
          margin-bottom: 900px;
          position: relative;
          &__services {
            display: flex;
            margin: 0 auto;
            .services-left {
              position: relative;
              top: 100px;
              right: -1px;
            }
            .services-left,
            .services-right {
              &__inner {
                .sv-card {
                  &.borderNone {
                    border-bottom: none;
                  }

                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  max-width: 600px;
                  min-height: 304px;
                  border: 1px solid #fff;
                  padding: 50px;
                  text-align: center;
                  position: relative;
                  &__label {
                    font-size: 2.5rem;
                    margin-bottom: 36px;
                  }

                  &__icon {
                    width: 16px;
                    height: 16px;
                    margin-bottom: 18px;
                  }
                  &__info {
                    font-size: 1.1rem;
                    font-family: "Rubik", sans-serif;
                    font-weight: 300;
                    letter-spacing: 1px;
                  }
                }
              }
            }
            .services-right {
              &__inner {
              }
            }
          }
        }
      }
    }

    #services-slider {
      top: 700%;
      width: 100%;

      @include media("<=tablet") {
        top: 236%;
      }

      .container {
        .section-inner {
          width: 100%;

          // div {
          //   width: 100%;
          //   display: flex;
          //   justify-content: center;
          //   svg {
          //     width: 500px;
          //     height: 500px;
          //     opacity: .1;
          //   }
          // }

          .section-slider__slider {
            width: 100%;

            .sv-card {
              &.borderNone {
                border-bottom: none;
              }

              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              max-width: 600px;
              min-height: 304px;
              border: 1px solid #fff;
              padding: 50px;
              text-align: center;
              position: relative;

              @include media("<=tablet") {
                padding: 14px;
              }

              &__label {
                font-size: 2.5rem;
                margin-bottom: 36px;

                @include media("<=tablet") {
                  font-size: 1.7rem;
                }
              }

              &__icon {
                width: 16px;
                height: 16px;
                margin-bottom: 18px;
              }
              &__info {
                font-size: 1.1rem;
                font-family: "Rubik", sans-serif;
                font-weight: 300;
                letter-spacing: 1px;

                @include media("<=tablet") {
                  font-size: 0.8rem;
                }
              }
            }
          }
        }
      }
    }

    .services {
      &#import {
        top: 640%;
      }

      &#agro {
        top: 905%;
        @include media("<=tablet") {
          top: 446% !important;
        }
      }

      &#declarant {
        top: 1170.2%;
        @include media("<=tablet") {
          top: 606% !important;
        }
      }

      @include media("<=tablet") {
        top: 286% !important;
      }

      .container {
        .section-inner {
          height: 100vh;
          padding-top: 120px;
          display: flex;
          position: relative;

          @include media("<=tablet") {
            padding-top: 0px;
          }

          &__content {
            position: absolute;
            top: 450px;

            @include media("<=tablet") {
              top: 210px;
            }

            .content-inner {
              max-width: 58%;
              margin-left: 120px;

              @include media("<=tablet") {
                max-width: 90%;
                margin-left: 16px;
              }

              &__subtitle {
                margin-bottom: 18px;
                @include media("<=tablet") {
                  width: 80%;
                  margin-bottom: 18px;
                }

                h1 {
                  font-size: 36px;
                  font-weight: 400;
                }
              }
              &__title {
                h3 {
                  font-size: 1.4rem;
                  font-weight: 300;
                  font-family: "Rubik", sans-serif;

                  @include media("<=tablet") {
                    font-size: 14px;
                  }
                }
              }
            }
          }
          &__media {
            .media-inner {
              max-width: 75%;
              margin-left: auto;

              @include media("<=tablet") {
                max-width: 100%;
              }

              img {
                width: 100%;
              }
            }
          }
        }
      }
    }

    .services {
      &#logistic {
        top: 1037.5%;

        .content-inner {
          margin-left: auto;

          @include media("<=tablet") {
            margin-right: auto;
          }
        }

        @include media("<=tablet") {
          top: 512% !important;
        }
      }

      &#build {
        top: 1303%;

        .content-inner {
          margin-left: auto;

          @include media("<=tablet") {
            margin-right: auto;
          }
        }

        @include media("<=tablet") {
          top: 688% !important;
        }
      }

      &#export {
        top: 772.5%;

        @include media("<=tablet") {
          top: 374% !important;
        }

        .container {
          .section-inner {
            &__content {
              .content-inner {
                margin-left: auto;

                @include media("<=tablet") {
                  margin-right: auto;
                }

                &__subtitle {
                  h1 {
                  }
                }
                &__title {
                  h3 {
                  }
                }
              }
            }
            &__media {
              .media-inner {
                max-width: 75%;
                margin-left: 0;

                @include media("<=tablet") {
                  max-width: 100%;
                }

                img {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}

footer {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 3;
  top: 1450%;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  @include media("<=500px") {
    top: 760%;
  }

  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 40px;

    .footer-globe {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: -1;
      bottom: -80%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      background-image: url("../media/globe-xs@2x.6e61f244.png");

      @include media("<=500px") {
        bottom: -93%;
      }
    }
    .footer-logo {
      width: 200px;
      display: flex;
      align-items: center;
      padding-top: 200px;
      @include media("<=500px") {
        width: 150px;
        padding-top: 534px;
      }

      span {
        width: 26px;
      }
    }

    .container {
      max-width: 1440px;
      margin: 0 auto;
      padding: 0 12px;

      @include media("<=1250px") {
        padding: 0 24px;
      }

      .footer-inner {
        display: flex;
        justify-content: center;
        &__cols {
          .footer__traits {
            width: 860px;
            display: flex;
            justify-content: space-between;

            @include media("<=500px") {
              width: 367px;

              position: relative;
              z-index: 5;
              top: -30px;
            }
            &-single {
              font-family: "Rubik", sans-serif;
              font-size: 32px;
              font-weight: 500;
              width: 122px;

              @include media("<=500px") {
                font-size: 24px;
                width: auto;
              }

              &:nth-child(1) {
                color: #de3350;
              }
              &:nth-child(2) {
                color: #f58334;
              }
              &:nth-child(3) {
                color: #64a8e0;
              }
            }
          }
          .footer__anim {
            position: absolute;
            width: 100%;
            top: 60%;
            left: 50%;
            transform: translate(-50%, -50%);

            @include media("<=500px") {
              top: 78%;
            }

            &-storke {
            }
          }
          .footer__copyrights {
            display: flex;
            justify-content: center;
            position: absolute;
            bottom: 4%;
            left: 50%;
            transform: translate(-50%, -50%);
            &-item {
            }
          }
        }
      }
    }
  }
}

.sv-card__info {
  a {
    display: flex;
    justify-content: end;
    margin-top: 20px;
    font-size: 22px;
    button {
      border-radius: 5px;
      color: #ffffff;
      cursor: pointer;
      background: none;
      padding: 4px 16px;
      border: 1px solid #ffffff;
    }
  }
}

.loader {
  display: none;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(45deg, #0d1119, #1a156f);

  .loader__logo {
    width: 16vh;
    svg {
      fill: white;
    }
    &-main {
      width: 110px;
      height: 110px;
    }
  }

  .loader__title {
    color: #f9f9f2;
    font-size: 2.6rem;
    text-transform: uppercase;
    font-weight: 600;
    line-height: 1;
    margin-top: 1rem;
    margin-bottom: 1.6rem;
    text-align: center;
  }

  .loader__subtitle {
    font-size: 0.8rem;
    letter-spacing: 0.2em;
    transition-delay: 0.6s;
    color: #64a8e0;
    margin-bottom: 0.5rem;
    transition: all 0.4 ease-in-out;
  }

  .loader__counter {
    span {
      transition: all 0.4 ease-in-out;
      color: #de3350;
    }
  }
}

#e9i56fx0l2vd9_to {
  animation: e9i56fx0l2vd9_to__to 1000ms linear 1 normal forwards;
}
@keyframes e9i56fx0l2vd9_to__to {
  0% {
    transform: translate(0px, -53.621101px);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  100% {
    transform: translate(0px, -0.001101px);
  }
}
#e9i56fx0l2vd9 {
  animation: e9i56fx0l2vd9_c_o 1000ms linear 1 normal forwards;
}
@keyframes e9i56fx0l2vd9_c_o {
  0% {
    opacity: 0;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  100% {
    opacity: 0.9;
  }
}

@keyframes e5l2clv9hdf3_ts__ts {
  0% {
    transform: translate(102.152889px, 100px) scale(1, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  50% {
    transform: translate(102.152889px, 100px) scale(-1.001805, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  100% {
    transform: translate(102.152889px, 100px) scale(1, 1);
  }
}
#e5l2clv9hdf9_to {
  animation: e5l2clv9hdf9_to__to 3000ms linear 1 normal forwards;
}
@keyframes e5l2clv9hdf9_to__to {
  0% {
    transform: translate(0px, -53.621101px);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  50% {
    transform: translate(0px, -0.001101px);
  }
  100% {
    transform: translate(0px, -0.001101px);
  }
}
#e5l2clv9hdf9 {
  animation: e5l2clv9hdf9_c_o 3000ms linear 1 normal forwards;
}
@keyframes e5l2clv9hdf9_c_o {
  0% {
    opacity: 0;
  }
  66.666667% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

/* Second */

#e305d2ah03aj3 {
  animation: e305d2ah03aj3_c_o 3000ms linear 1 normal forwards;
}
@keyframes e305d2ah03aj3_c_o {
  0% {
    opacity: 0;
  }
  33.333333% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (min-width: 1537px) {
  .loader__logo {
    width: 13vh !important;
  }
}
